.footer-bg {
    /*background-image: url('/public/kids_bg.jpg');
    background-color: #000;*/
    background-position: 'center';
    background-size: 'cover';
    background-repeat: 'no-repeat';
}
.social-icon {
    margin: 10px;
    font-size: 50px !important;
    border-radius: 10px;
    padding: 10px;
    /* border: 1px solid #aaa; */
    transition: all 0.9s ease-in-out;
    -webkit-transition: all 0.9s ease-in-out;
    background-color: #fff;
}
.social-icon:hover{
    color: #fff;
    background-color: #00000033;
    transition: all 0.9s ease-in-out;
    -webkit-transition: all 0.9s ease-in-out;
}
