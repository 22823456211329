.logo-area {
    display: inline-block;
    position: relative;
    top: 11px;
    line-height: 1;
    small {
        font-size: 12px;
    }
}

.nav-link{
    font-weight: 500 !important;
}